import React from 'react';

function Hero({ styling, viewBox, imageFill }) {
  var theID = `Hero${imageFill}`;
  return (
    <>
      {imageFill ? (
        <svg className={styling || ''} viewBox={viewBox || '0 0 707 566'} xmlns="http://www.w3.org/2000/svg">
          <defs>
            <pattern id={theID} patternUnits="userSpaceOnUse" width="566" height="707">
              <image href={imageFill} x="0" y="0" width="566" height="707" />
            </pattern>
          </defs>
          <path
            d="M384.775 112.889L479.579 3.45234C481.478 1.25973 484.236 0 487.137 0H696.655C704.772 0 709.507 9.16052 704.813 15.7828L317.801 561.783C315.926 564.428 312.885 566 309.642 566H104.767C96.4156 566 91.7434 556.369 96.9125 549.81L144.285 489.698C149.454 483.139 144.782 473.509 136.431 473.509H10.5658C2.40651 473.509 -2.31846 464.264 2.46095 457.651L246.145 120.484C248.025 117.882 251.04 116.341 254.25 116.341H377.217C380.118 116.341 382.876 115.081 384.775 112.889Z"
            fill={`url(#${theID})`}
          />
        </svg>
      ) : (
        <svg className={styling || ''} viewBox={viewBox || '0 0 707 566'} xmlns="http://www.w3.org/2000/svg">
          <path d="M384.775 112.889L479.579 3.45234C481.478 1.25973 484.236 0 487.137 0H696.655C704.772 0 709.507 9.16052 704.813 15.7828L317.801 561.783C315.926 564.428 312.885 566 309.642 566H104.767C96.4156 566 91.7434 556.369 96.9125 549.81L144.285 489.698C149.454 483.139 144.782 473.509 136.431 473.509H10.5658C2.40651 473.509 -2.31846 464.264 2.46095 457.651L246.145 120.484C248.025 117.882 251.04 116.341 254.25 116.341H377.217C380.118 116.341 382.876 115.081 384.775 112.889Z" />
        </svg>
      )}
    </>
  );
}

export default Hero;
