import React from 'react';
import styled from './index.module.css';
import SEO from '../components/SEO';
import IndexPageHeroSection from './index/IndexPageHeroSection';
import IndexPageFeaturedProductRoll from './index/IndexPageFeaturedProductRoll';
import Layout from '../components/Layout';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title={'Woodworking Wednesday'} article={false} />
      <div className={styled.IndexPageLayout}>
        <div className={styled.hero}>
          <IndexPageHeroSection />
        </div>
        <div className={styled.productRoll}>
          <IndexPageFeaturedProductRoll />
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
