import React from 'react';
import styled from './index.module.css';
import { Link } from 'gatsby';
import ImageMakersMark from '../../../images/imageComponents/ImageMakersMark';
import ImageHero from '../../../images/imageComponents/ImageHero';
import { useHeroImageQuery } from '../../../hooks/useHeroImageQuery';

const IndexPage = () => {
  const hero = useHeroImageQuery();
  // { bigHero, smallHero }
  console.log(hero);
  return (
    <div className={styled.wrapper}>
      <div className={styled.heroGrid}>
        <div className={styled.logo}>
          <Link className={styled.link} to="/">
            WOODWORKING WEDNESDAY
          </Link>
        </div>
        <div className={styled.links}>
          <Link className={styled.link} to="/products">
            PIECES
          </Link>
          <div className={styled.link}>-</div>
          <Link className={styled.link} to="/blog">
            BLOG
          </Link>
        </div>
        <div className={styled.introHead}>Woodworking as a hobby, are you bla bla on the lalala?</div>
        <div className={styled.landingPageIntroText}>
          <div className={styled.outsideShape} />
          <div className={styled.introBody}>
            Motherfuckes out here talking mad shit and... like I’m not even trippin’ shits whild... about woodworking
            they are talking mad shit that is anyway...
          </div>
        </div>
        <img
          className={styled.bigHeroImage}
          srcset="https://res.cloudinary.com/gorlipark/image/upload/w_320/v1615886223/Woodworking%20Wednesday/6_c3ochg.jpg 640w,
          https://res.cloudinary.com/gorlipark/image/upload/w_384/v1615886223/Woodworking%20Wednesday/6_c3ochg.jpg 768w,
          https://res.cloudinary.com/gorlipark/image/upload/w_512/v1615886223/Woodworking%20Wednesday/6_c3ochg.jpg 1024w,
          https://res.cloudinary.com/gorlipark/image/upload/w_800/v1615886223/Woodworking%20Wednesday/6_c3ochg.jpg 1600w,
          https://res.cloudinary.com/gorlipark/image/upload/w_960/v1615886223/Woodworking%20Wednesday/6_c3ochg.jpg 1920w"
          src="https://res.cloudinary.com/gorlipark/image/upload/w_780/v1615886223/Woodworking%20Wednesday/6_c3ochg.jpg"
          alt="some nice old tools"
        />
        <ImageHero styling={`${styled.bigHeroOutline} ${styled.outline}`} />

        <img
          className={styled.smallHeroImage}
          srcset="https://res.cloudinary.com/gorlipark/image/upload/c_lfill,h_44,w_220,x_1595,y_1674/v1615886223/Woodworking%20Wednesday/2_mdsjbr.jpg 640w,
          https://res.cloudinary.com/gorlipark/image/upload/c_lfill,h_62,w_307,x_1595,y_1674/v1615886223/Woodworking%20Wednesday/2_mdsjbr.jpg 768w,
          https://res.cloudinary.com/gorlipark/image/upload/c_lfill,h_82,w_410,x_1595,y_1674/v1615886223/Woodworking%20Wednesday/2_mdsjbr.jpg 1024w,
          https://res.cloudinary.com/gorlipark/image/upload/c_lfill,h_128,w_640,x_1595,y_1674/v1615886223/Woodworking%20Wednesday/2_mdsjbr.jpg 1600w,
          https://res.cloudinary.com/gorlipark/image/upload/c_lfill,h_154,w_768,x_1595,y_1674/v1615886223/Woodworking%20Wednesday/2_mdsjbr.jpg 1920w"
          src="https://res.cloudinary.com/gorlipark/image/upload/c_lfill,h_120,w_600,x_1595,y_1674/v1615886223/Woodworking%20Wednesday/2_mdsjbr.jpg"
          alt="A chisel on a bench"
        />
        <ImageMakersMark styling={styled.makersMark} />
        <div className={`${styled.smallHeroImageOutline} ${styled.outline}`} />
      </div>
    </div>
  );
};

export default IndexPage;
