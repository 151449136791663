import { useStaticQuery, graphql } from 'gatsby';
// TODO move all images to cloudinary
export const useHeroImageQuery = () => {
  return useStaticQuery(graphql`
    {
      bigHero: file(relativePath: { eq: "6.jpg" }) {
        id
      }

      smallHero: file(relativePath: { eq: "3.jpg" }) {
        id
      }
    }
  `);
};
